import './signin.css'
import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useState } from 'react';
// import Banner from '../components/landing/components/banner';
// import Feature from '../components/landing/components/feature';
// import Process from '../components/landing/components/process';
// import Partner from '../components/landing/components/partner';
// import Try from '../components/landing/components/try';
// import Certifications from '../components/landing/components/certifications';
import Header from '../components/landing/components/header';
import Footer from '../components/landing/components/footer';

function SigninPage() {
  const [isSignup, setIsSignup] = useState(false);

  const toggleForm = () => {
    setIsSignup(!isSignup);
  };

  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header />

      <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "auto", padding: "55px 0" }}>
        {/* <div className="container">
          <div className="breadcrumb_content text-center" data-aos-delay={100}>
            <h1 className="page_title" style={{ fontSize: "25px" }}>Pricing Plans</h1>
            <p className="mb-0">
              Stay ahead of the curve with
              Extraise Pro.
            </p>
          </div>
        </div>
        <div className="deco_image spahe_1" data-aos="fade-down" data-aos-delay={300}>
          <img src="/assets/images/shapes/shape_1.png" alt="spahe_not_found" />
        </div>
        <div className="deco_image spahe_2" data-aos-delay={400}>
          <img src="/assets/images/shapes/shape_2.png" alt="spahe_not_found" />
        </div> */}
      </section>
      <main>

        <section className='sec_ptb_120 bg_gray ' >
          <div className={`auth-container`}>
            
            <div className="auth-form sign-in-container">
              <form action="#">
                <h1>Sign In</h1>
                <div className="social-container">
                
                  <a href="#" className="social">
                    {/* <FaGoogle /> */}
                  </a>
        
                </div>
                <span>or use your account</span>
                <input type="email" name="email" placeholder="Email" />
                <input type="password" name="password" placeholder="Password" />
                <a href="#">Forgot Your Password</a>
                <button>Sign In</button>
                <a href="/signup" >Don't Have an Account - Click here</a>
              </form>
            </div>
            
          </div>
        </section>


        {/* <Feature /> */}
        {/* <Process /> */}
        {/* <Partner /> */}
        {/* <Try /> */}

      </main>
      <Footer />
    </>
  );
}

SigninPage.displayName = 'SigninPage';

export default connect(null)(SigninPage);
